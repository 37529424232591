.header {
  display: flex;
  align-items: center;
  background-color: white;
  position: sticky;
  top: 0;
  border-bottom: 2px solid whitesmoke;
}
.allLogos{
  display: flex;
}
.specifywh{
  width: 160px;
  height: 165px;
font-family: 'Source Sans Pro';
font-weight: 600;
font-size: 16px;
padding: 5px 10px;
  object-fit: contain;
  border-radius: 2px;
  overflow: hidden;
}
.regionStyling:focus{
  outline: 1.5px solid grey !important;

}
.selectorBtn{
  border: none !important;
  background-color: white !important;
}
/* .headlogo:focus{
border: 1.5px solid grey !important;
} */
.ReSearch_logo:focus{
  border: 1.5px solid  grey !important;
  }
.researchLink:focus{
  border: 1.5px solid grey !important;
}
.hamburger {
  display: none;
}
.header_logo {
  object-fit: contain;
  padding-top: 19px;
  width: 80px;
  margin-right: 15px;
}
.globe:focus{
  border: 1.5px solid grey !important;
}

.mobilehamburger {
  display: none;
}
/* .nobgColor{
  background-color: #ffffff;
  border: none;
} */
.verbar {
  border-left: solid 2px #b1b2b4;
  height: 40px;
  margin-top: 20px;
  padding-right: 15px;
}

.ReSearch {
  padding-top: 17px;
  color: darkgrey;
  object-fit: contain;
}
.research_logo {
  width: 80px;
  height: 40px;
  object-fit: contain;
}

.header_nav {
  text-align: center;
  margin-top: 18px;
  text-justify: auto;
  height: 20px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: Source Sans Pro;
  
}
#Research {
  width: 100px;
  height: 20px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #e30613;
  text-decoration: none;
}

.globe {
  
 display: inline-block;
 margin-left: auto;
  
}
.region {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.myUserProfile .popover-body{
  width:225px
}
.EMEA {

  height: 22px;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 600;
  padding-left: 3px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  cursor: pointer;
  display: inline-block;
}
.profileIcon{
  display: inline-block;
  margin-right: 16px;
}
.globeRegion{
  display: inline-block;
  padding-top: 15px;
  margin-left: auto;
  margin-right: 5px;
}
.oval {
  width: 30px;
  height: 30px;
  background-color: #626468;
  border-radius: 30px;
  text-align: center;
  padding-top: 2px;
  cursor: pointer;
}
.mobileViewbasket1 {
  display: none;
}
.nav-item {
  outline: 0;
  background-color: #fff;
  border-color: #fff #fff #2a2c2d #fff;
  padding: 5px 0 2px 0;
}

.popover {
  border: none;
}
.regionPop .dropdownStyle .dropdown {
  position: relative;
  display: inline-block;
  font-family: Source Sans Pro;
}
/* .regionPop :hover .dropdown-content {display: block;} */
.regionPop .dropdownStyle .dropdown-content {
  position: absolute;
  background-color: white;
  width: 200px !important;
  height: 165px !important;
  border-radius: 2px;
  padding-top: 20px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9;
  margin-top: 50px;
  cursor: pointer;
}

#popover-contained{
  border: none;
}
#popover-basic{
  border: none;
}
.regionPop .dropdownStyle .dropdown .dropdown-content::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 80%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}
.regionPop .regionStyling :hover {
  background-color: grey;
  text-decoration: none !important;

  transform: scale(1);
}
.fullBg:hover{
background-color: #f2f2f2;
}
.dropdown-content:hover {
  text-decoration: none;
}
.regionPop .regionStyling {
  height: 20px;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #626468;
  padding-left: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
   text-decoration: none;
}

/* .margin-profile{

    margin-left:970px
  } */
/* .margin-region{
    margin-left:880px
  } */
  .client_logo{
    width: 70px;
    height: 36px;
    object-fit: contain;
    margin-top: 20px;
  }
  .hide_logo{
    display: none;
  }

  .profileicon.dropdown {
    position: relative;
    display: inline-block;
    font-family: 'Source Sans Pro';
  
  }
  

  
  
  .OvalPro {
    width: 94px;
    height: 94px;
    background-color: #626468;
    border-radius: 60%;
    text-align: center;
    padding-top: 7px;
    font-size: 50px;
    cursor: pointer;
  
  }
  
 
  .names{
    margin-top: 10px;
  text-align: -webkit-center;
  }
  
  
  .longname {
    font-family: Source Sans Pro;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    margin-top: 10px;
   color: #000000;
  }
  
  
  .settings {
    padding-left: 15px;
    cursor: pointer;
  }
  .settings:hover{
    background-color:#f2f2f2;
  }
  .logout {
    padding-left: 15px;
    cursor: pointer;
    padding-bottom: 15px;
  }
  .logout:hover{
    background-color:#f2f2f2;
  }
  .droptext {
    display: inline;
    padding-left: 10px;
    font-family: Source Sans Pro;
    color: #626468;
    font-size: 14px;
    font-weight: 600;
  }
  .horLine {
 
    margin-left: 0px;
    margin-right: 15px;
  }
  .logoMargin{
    margin-right: 10px;
  }
  @media screen and (max-width: 567px) {
    .dropdown-content {
      display: none;
    }
    .logoMargin{
     display: none;
    }
  }


@media screen and (max-width: 480px) {
  .logoMargin{
    display: none;
   }
  .header {
    padding-left: 10px;
    display: contents;
  }
  .header_logo {
    margin-top: 18px;
    width: 65px;
  }
  .profileIcon{
    display: none;
  }

  .research_logo {
    margin-top: 17px;
    width: 65px;
    margin-right: 45px;
  }
  .verbar {
    border-left: solid 2px #b1b2b4;
    height: 30px;
    margin-top: 35px;
    padding-right: 15px;
  }
  .navbar {
    display: none;
  }
  .regionPop{
    display: none;
  }
  .header_nav {
    display: none;
  }
  .navbar {
    display: none !important;
  }
  .globeRegion{
    display: none
  }

  .oval {
    display: none;
  }

  .mobileViewbasket1 {
    display: inline-block;
     /* padding-left: 35px !important;  */
    padding-top: 15%;

  }
  /* .BaskBell{
    padding-left: 12px !important;
  } */
  .BasketOvalCount{
    width: 20px;
    height: 20px;
    border: solid 2px #ffffff;
    background-color: #e30613;
    border-radius: 20px;
    padding-top: 8px;
    font-size: 12px;
    background: #e30613;
    position: absolute;
    top: 8px;
    text-align: center;
    right: 10px;
    color: #ffff;

 
  }
  .NotifyOvalCount{
    width: 20px;
    height: 0px;
    border: solid 2px #ffffff;
    background-color: #e30613;
    border-radius: 20px;
    padding: 8px 10px 10px 5px;
    margin-right: 30px;
    font-size: 12px;
    background: #e30613;
    position: absolute;
    top: 8px;
    right:10px;
    color: #ffff;
  

  }
  .bell {
    padding-left: 10px;
  }
  .client_logo{
    display: none;
  }
}

@media screen and (min-width: 1024px) and (max-width:1279px){
 
  .globeRegion{
    display: inline-block;
    padding-top: 18px;
    margin-left: auto;
  }
}


@media (min-width: 992px) {
  .navbar {
    margin-left: 12.7rem;
  }
 
}
@media (min-width: 1200px) {
  .navbar {
    margin-left: 17.7rem;
  }
 
}


@media screen and (max-width: 768px) and (min-width:600px) {
  .header_nav {
    font-size: 12px;
    font-weight: 600;
  }
  #Research {
    font-size: 12px;
    font-weight: 600;
  }
  .globeRegion{
    display: inline-block;
    padding-top: 18px;
    margin-left: auto;
    width:auto;
  }

}
 @media screen and  (max-width:360px){
  .logoMargin{
    display: none;
   }

.research_logo {
    margin-top: 17px;
    width: 65px;
    margin-right: 35px;
}
  .BasketOvalCount{
    width: 20px;
    height: 20px;
    border: solid 2px #ffffff;
    background-color: #e30613;
    border-radius: 20px;
    padding-top: 8px !important;
    text-align: center !important;
    font-size: 12px;
    background: #e30613;
    position: absolute;
    color: #ffff;
 
  }
} 

@media screen and (max-width:2048px) and (min-width:1930px){
  .header_nav{
    margin-left: 400px;
  }
  .header_logo {
    margin-left: 12px;
  }


}
@media screen and (max-width:1610px) and (min-width:1400px){

  .header_nav{
    margin-left: 100px;
  }
}
@media screen and (max-width:1800px) and (min-width:1650px){

  .header_logo {
    margin-left: 6px;
  }
  .header_nav{
    margin-left: 120px;
  }

}
@media screen and (max-width:1640px) and (min-width:1580px)
{

  .header_logo {
    margin-left: 10px;
  }
}
@media screen and (max-width:1927px) and (min-width:1890px){
  .header_nav{
    margin-left: 330px;
  }
}


