.footer {
  height: 100px;
  background-color: black;
  font-weight: 400;
  font-family: Source Sans Pro;
  bottom: 0;
  width: 100%;
  position: absolute;
  z-index: 99;
}
.surveyFooterStyle {
  height: 45px;
  background-color: black;
  font-weight: 400;
  font-family: Source Sans Pro;
  bottom: 0;
  width: 100%;
  position: absolute;
  z-index: 99;
  top: 795px !important;
}
.footerStyle {
  padding-left: 75px;
  padding-top: 30px;
  padding-right: 35px;
  /* padding-bottom: 30px; */
}
.footerLogo {
  width: 80px;
  height: 40px;
  object-fit: contain;
  margin-right: 20px;
}
.copyRight {
  color: white;
}

.tou::before {
  content: "|";
  margin-left: 4px;
  margin-right: 4px;
}
.fu::before {
  content: "|";
  margin-left: 4px;
  margin-right: 4px;
}
.facebook {
  margin-right: 10px;
  margin-left: 10px;
  z-index: -1;
 
}
.btn-acc{
  border: none;
  background-color: black;
  color: white;
  padding: 0px;
  
} .bb:focus{
  border: 2px solid ;
  /* padding: 1px; */
}

.twitter {
  margin-right: 10px;
}
.line {
  display: inline-block;
}
.ps {
  margin-left: 0px;
}

.ps > a {
  color: white !important;
  text-decoration: none;
  background-color: transparent;
}
.tou > a{
  color: white !important;
  text-decoration: none;
  background-color: transparent;
}
.mobileFooter {
  display: none !important;
}
@media screen and (max-width: 900px) and (min-width: 600px) {
  .footerLogo {
    text-align: center;
  }
  .copyRight {
    font-size: 14px;
  }
  .ps {
    margin-left: -29px;
    font-size: 14px;
  }
  .follow {
    font-size: 20px;
  }
  .btn-acc{
    padding: 0px;
    font-size: 12px;
  }
  .facebook {
    margin-right: 7px;
    margin-left: 8px;
    z-index: -1;
  }
  .twitter {
    margin-right: 5px;

  }
 

  .fu {
    font-size: 14px;
  }
  .facebook,
  .twitter,
  .linkedin {
    width: 16px;
  }
}
@media screen and (max-width: 760px) {
  .desktopFooter {
    display: none !important;
  }
  .mobileFooter {
    display: inline-block !important;
  }
  .footer {
    height: 200px;
    text-align: center;
    z-index: auto;
  }
  .footerStyle {
    padding-right: 30px;
    padding-left: 30px;
  }
  .ps {
    margin-left: 0px;
    width: 100%;
    display: inline-block;
  }
  .service {
    display: flex;
    flex-wrap: wrap-reverse;
    width: 100%;
  }
  .fu::before {
    content: none;
  }
  .follow {
    width: 100%;
    padding-top: 18px;
    padding-bottom: 7px;
  color: white;
  }
  .right {
    display: flex;
    flex-wrap: wrap;
  }
  .copyRight {
    width: 100%;
    color: gray;
    padding-top: 13px;
  }
}
