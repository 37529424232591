.homeImg {
    width: 100%;
    height: 670px;
    background-color: rgba(0, 0, 0, 0.1);
    filter: brightness(70%);
}
.displayInline{
    /* display: inline-block; */
    margin-right: 2%;
    margin-left: 2%;
}
.paddingLeft{
    padding-left: 2%;
}
.setMargin{
    margin-right: 2%;
    margin-left: 2%;
    font-family: Source Sans Pro;
    font-size: 15px;
    color: #000000;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;

}
.text-right{
    text-align: right;
}
.homeImageContainer {
    position: relative;
    text-align: center;
    padding: 0 !important;
    max-width: 100% !important;
}
.reis-see-all{
    background: transparent;
    border: none;
}
a.skip-main {

    position: absolute;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    z-index: -999;
  }
  a.skip-main:focus {
    width: 30%;
    height: auto;
    color:#512da8;
   
    overflow: auto;
    font-size: 22px;
    text-align: center;
    z-index: 999;
    border: 1px solid black;
    background-color:#DFFF00;
  }
.welcomeTitle {
    padding-left: 20px;
    text-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    font-family: Source Sans Pro;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}
.alertWelcomeTitle {
    padding-left: 20px;
    text-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    font-family: Source Sans Pro;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}

.welcomeMessg {
    font-family: Source Sans Pro;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
}
.welcomeSubText{
    display: contents;
}
.new-reis-container {
    padding: 5rem 0;
}

.new-reis-container .contents {
    padding-right: 1%;
    padding-left: 1%;
    display: contents;
}
.alertToastMssg{
    left: 0 ;
    right: 0;
    top: 44%;
    width: 253px;
    opacity: 0.8;
    margin: auto; 
    z-index:999999;
    position: fixed;
    text-align: center;
    border-radius: 26px !important;
    background-color: #626468 !important;
    box-shadow: none !important;
  }
  .desc{
    /* width: 207px; */
  height: 39px;
  font-size: 14px;
  color: #ffffff;
  overflow: hidden;
  font-weight: bold;
  font-style: normal;
  text-align: center;
  white-space: nowrap;
  line-height: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-family: "Source Sans Pro";
  }
.new-reis-container .new-reis-card {
    height: 80px !important;
    width: 100% !important;
    margin: 20px 0px 0 0;
    padding: 20px 0px 20px 0;
    border-radius: 5px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
}

.new-reis-card .form-check {
    margin-top: 0 !important;
    margin-bottom: 20px;
}
.new-reis-card input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: solid 1.5px #b1b2b4;
    position: relative;
    bottom: 10px;
    left: 12px;
    top: 3px;
}
.textAlign-center{
    text-align: right;
}

.doc_new_reis_icon {
    width: 40px;
    height: 40px;
    margin: 0 10px;
    object-fit: contain;
}

.new-reis-card .new_reis_card-text {
    width: 100%;
    font-family: Source Sans Pro;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-top: 8px;
    text-align: left;
}

.most-recent-REIS {
    width: 300px;
    height: 30px;
    font-family: Source Sans Pro;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: #000000;
    display: inline;
}

.see-all-reis {
    float: right;
    height: 20px;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #e30613;
    cursor: pointer;
    background: transparent;
    border: none;
  }
  .download-reis {
    width: 30px;
    height: 30px;
    object-fit: contain;
    cursor: pointer;
    margin-top: 3px;
}

.new-reis-download-selected {
    text-align: center;
}

.new-reis-download-selected .home-download-selected {
    width: 250px;
    height: 50px;
    border-radius: 5px;
    border: solid 1px #e30613 !important;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    background: #ffffff !important;
    position: absolute;
    left: 39%;
    margin-top: 3%;
    color: #e30613 !important;
}

.download-btn-text {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding: 0px !important;
}
.homeCardContainer .card {
    width: 280px;
    height: 280px;
    border-radius: 5px !important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.08);
    background-color: rgba(255, 255, 255, 0.8);
}

.homeCardContainer {
    position: relative;
    top: -155px;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.homeCardIcon {
    object-fit: contain;
    text-align: right;
    padding: 30px 0px 40px 126px;
    width: 100%;
}

.homeCardType {
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: start;
    color: #e30613;
    padding-left: 12px;
    padding-top: 22px;
}

.homeCardName {
    font-family: Source Sans Pro;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    padding-left: 12px;
    text-align: start;
}

/* Check box customization */

.form-check {
    margin-top: 20px;
    margin-bottom: 20px;
    ;
}

.carouselTitle {
    font-family: Source Sans Pro !important;
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: #000000;
    padding-bottom: 2%;
    padding-left: 1.5%;
    width: 100%;
    text-align: left;
}
.carousel-indicators{
    top:105%;
}

.carousel-indicators li {
    width: 10px !important;
    height: 11px !important;
    opacity: 0.7;
    background-color:#626468 !important;
    border-radius: 50%;
}

.carousel-indicators .active {
    height: 10px;
    width: 24px !important;
    background-color: #ffffff;
    border-radius: 0.5rem;
    border: 0px solid #ffffff;
    margin-top: 3.2%;
}

.tagBackground {
    height: 25px;
    background-color: #e30613;
   /*  padding-right: 30%;
    padding-left: 30%; */
    padding-right: 1.5%;
    padding-left: 1.5%;
}

.tagName {
    font-family: Source Sans Pro;
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
   /*  padding-top: 1%; */
    z-index: 9;
}

.carouselTitleStyle {
    text-align: left;
    font-family: Source Sans Pro;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin-top: 2%;
}

.dateStyle {
    text-align: left;
    font-family: Source Sans Pro;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #626468;
    margin-top: 2%;
}

.carouselImg {
    height: 480px;
}

.back-to-topInHome {
    display: inline-block;
    float: right;
    position: fixed;
    right: 0.7rem;
    bottom: 1rem;
    width: 65px;
    height: 78px;
    color: white;
    border-radius: 5px;
    font-size: 10px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
    background:rgba(0, 0, 0, 0.700);
    /* outline: 1px solid blue; */
    z-index: 9999999;
    line-height: 11px;
}

.label-back-to-top {
    right: 2rem;
    top: 1rem;
    position: relative;
    width: 6rem;
    color: #e30613;
    font-weight: bold
}

.tagContainer {
    height: 100%;
}

.tagContainer .col-12 {
    border-bottom: 1px solid #f2f2f2;
    height: 25%;
}

.tagContainer .active {
    background-color: #f2f2f2;
}

.noBottomMargin {
    margin-bottom: 0px;
}

.tagWrapper {
    padding-top:15px;
    padding-bottom:12px;
}

.homeCardIconMob {
    display: none;
}

.displayNone {
    display: none;
}

@media screen and (min-width: 992px) and (max-width:1200px) {
    .homeCardContainer .card {
        height: 250px
    }
    .homeCardName {
        font-size: 20px;
    }
    .homeCardIcon {
        width: 95%;
        padding: 30px 0px 40px 92px
    }
    .welcomeMsgContainer {
        padding-left: 20px!important;
    }
}

@media screen and (max-width: 767px) {
    .mobileBanner {
        position: relative;
        text-align: center;
        color: white;
    }
    .back-to-topInHome{
        background-color: #626468;
        width: 50px;
        height: 50px;
        z-index: 1 !important;
    }
    .mobileBanner span {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, 108%);
        width: 93%;
        height: 50px;
        font-family: Source Sans Pro;
        border-radius: 5px;
        backdrop-filter: blur(5px);
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.08);
        background-color: white;
    
    }
    .mobileBanner span input {
        height: 50px;
        border: none;
        box-shadow: none;
        padding: 0 .75rem;
        font-size: 12px;
    }
    .mobileBanner span input:focus{
        outline: none;
        box-shadow: none;
    }
    .homeImg {
        height: 156px;
    }
    .homeCardContainer {
        position: relative;
        top: 0px;
        margin-top: 78px;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .homeCardContainer .cardDeck {
        width: 100%;
    }
    .homeCardIconMob {
        display: block;
        margin: auto;
        width: 35%;
    }
    .homeCardIcon {
        display: none;
    }
    .homeCardType {
        padding-left: 0px !important;
        padding-top: 0px !important;
        text-align: center;
        color: #626468;
        font-size: 14px;
    }
    .homeCardName {
        font-size: 16px;
        text-align: center;
        padding-left: 0px !important;
    }
    .homeCardContainer .card {
        width: 100%;
        height: 100%;
        padding: 0 0 40px 0;
        box-shadow: none !important;
        border: 0 !important;
    }
    .welcomeMsgContainer {
        text-align: center;
        position: absolute;
    }
    .welcomeMsgContainer .welcomeMessg {
        display: none !important;
    }
    #popover-trigger-click-root-close {
        display: none;
    }
    .welcomeTitle {
        color: black;
        font-size: 18px;
        text-align: center;
        padding-bottom: 4%;
    }
    .maxWidth{
        width:100%;
    }
    .carouselTitle {
        font-size: 20px;
        padding-left: 15px;
        padding-top: 25px;
    }
    .tagContainer {
        margin-left: 0;
        margin-right: 0;
        border-bottom: 4px solid #f2f2f2;
        border-radius: 5px;
    }
    .tagWrapper {
        display: none;
    }
    .tagContainer .col-12{
        height:auto;
    }
    .setMargin{
        font-size: 10px;
        -webkit-line-clamp: 3;    
    }
    
    .tagWrapper.active {
        display: block;
        background-color: #f2f2f2;
    }
    .tagName {
        position: absolute;
        top:-8px;
        left: 15px;
    }
    .carouselImg {
        height: 335px;
    }
    .carouselTitleStyle {
        font-size: 14px;
    }
    .dateStyle {
        font-size: 14px;
        padding-bottom: 11px;
    }
    .tagName {
        font-size: 16px;
    }
    .carousel-indicators {
        top: 134%;
        background-color:#626468 !important;
    }
    .carousel-indicators li{
        background-color:#626468!important;
        background-clip: unset !important;
        border-top-width: 0px !important;
        border-bottom-width: 0px !important;
    }
    .carousel-indicators, .carousel-indicators .active {
        background-color: #626468 !important;
    }

    .carousel-indicators .active {
        margin-top: 0 !important;
    }
    .new-reis-container {
        padding: 0rem 1rem;
        margin-bottom: 15%;
    }
    .new-reis-card .new_reis_card-text {
        font-family: Source Sans Pro;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
        overflow: visible;
        display: flex;
        align-items: center;
        justify-content:flex-start;
        margin-left: -20px;
    }
    .most-recent-REIS {
        width: 153px;
        height: 25px;
        margin: 0 120px 20px 0;
        font-family: Source Sans Pro;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #000000;
        display: contents;
    }
    .see-all-reis {
        width: 41px;
        height: 18px;
        font-family: Source Sans Pro;
        font-size: 14px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #e30613;
        float: right;
        display: contents;
        cursor: pointer;
    }
    .see-all-next {
        width: 20px;
        height: 20px;
        object-fit: contain;
    }
    .doc_new_reis_icon {
        width: 30px;
        height: 30px;
        margin: 0;
        object-fit: contain;
    }
    .new-reis-container .new-reis-card {
        width: 100% !important;
        height: 40px !important;
        margin: 20px 0 0;
        padding: 1% 7% 1% 0;
        border-radius: 5px;
        box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.08);
        background-color: #ffffff;
    }
    .download-reis {
        width: 20px;
        height: 20px;
        margin: 5px 0 5px 10px;
        object-fit: contain;
        cursor: pointer;
    }
    .toHide {
        display: none !important;
    }
}

@media screen and (min-width: 768px) {
    .welcomeMsgContainer {
        text-align: center;
        position: absolute;
        top: 170px;
        left: 50%;
        padding-left: 116px !important;
        transform: translate(-50%, -50%);
        margin-top: 264px;
        height: 320px;
        z-index: 1;
    }
}

@media screen and (max-width: 992px) and (min-width:768px) {
    .tagWrapper {
        padding-top: 6px;
        padding-bottom: 5px;
    }
    .carousel-indicators .active {
        margin-top: 5.2%;
    }
    .carouselImg {
        height: 315px;
        margin-top: 4%;
    }
    .tagName {
        font-size: 14px;
    }
    .carouselTitleStyle {
        font-size: 13px;
    }
    .dateStyle {
        font-size: 12px;
        margin-top: 1%;
    }
    .welcomeMessg {
        margin-left: 60px;
    }
    .homeCardContainer .card {
        height: 185px;
    }
    .homeCardContainer .card-deck {
        margin-top: 4%;
    }
    .homeCardType {
        padding-left: 2px;
        padding-top: 42px !important;
    }
    .homeCardName {
        padding-left: 2px;
        font-size: 84% !important;
    }
    .homeCardIcon {
        width: 54%;
        margin-left: 48%;
        margin-top: 10%;
        padding: 0;
    }
    .toHide {
        display: none !important;
    }
}