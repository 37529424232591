.downloadHere:hover {
  color: #e30613;
  cursor: pointer;
  text-decoration: underline;
}
.downloadHere {
  color: #e30613;
  cursor: pointer;
}

@media screen and (max-width: 767px) {

    .welcomeMsgContainer .welcomeMessg {
        display: flex !important;
        margin-left: 5px;
        margin-top: -105px !important;
    }

    .profileIcon{
        display: inline !important;
        position: absolute !important;
        top: 0;
        right: 0;
      }

      .homeImg {
        width: 110% !important;
        height: auto;
    }
    .oval {
    display: inline-block !important;
    }

    .surveyFooterStyle {
    width: 100% !important;
    }

    .mobileFooter {
        display: inline-block !important;
        position: fixed;
      }
}

@media screen and (max-width: 567px) {
    .dropdown-content {
      display: block !important;
    }
    .logoMargin{
     display: block !important;
    }
  }