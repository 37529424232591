.modalStyle .modal-content {
  /* width: 380px !important; */
  width: auto !important;
  /* height: 351px; */
  border-radius: 5px !important;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #ffffff;
}

.titleStyle {
  width: 74px;
  height: 25px;
  font-family: Source Sans Pro;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
}
.padleft {
  margin-left: 6px;
}
.defaultPage {
  /* width: 149px; */
  /* height: 18px; */
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #626468;
  padding-bottom: 10px;
  padding-top: 20px; 
}
.defaultscreen{
  width: 149px;
  /* height: 18px; */
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #626468;
  padding-bottom: 10px;
  padding-top: 20px;
}

/* .dropdownStyle .dropdown {
  position: relative;
  display: inline-block;
  font-family: Source Sans Pro;
}

.dropdownStyle .dropdown-content {
  position: absolute;
  background-color: white;
  width: 200px !important;
  height: 171px !important;
  border-radius: 2px;
  padding-top: 20px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 9;
} */
.regionDrop .regionDropdownContent .dropdownWrapper {
  width: 335px;
  height: max-content;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #ffffff;
  margin-top: -10px;
  padding-top: 5px;
  padding-right: 4px;
  padding-bottom: 10px;
  color: #000000;
}
.regionDrop .regionDropdownSize .regionDropdown {
  width: 335px !important;
  height: 50px;
  border: solid 1px #d9d9d9;
  border-radius: 5px !important;
  background-color: #ffffff;
  text-align: initial;
  color: #000000;
  font-weight: 600;
  font-family: Source Sans Pro - Semibold;
}
.regionDrop .regionDropdownSize .regionDropdownContent {
  width: 335px;
  height: 234px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #ffffff;
  color: #000000;
}
.regionDrop .regionDropdown .btn-primary:hover {
  background-color: white;
}
.regionDropdown.dropdown-toggle::after {
  margin-left: 12.25rem;
  content: none;
}
.langDrop .langDropdownSize .langDropdown {
  width: 335px !important;
  height: 50px;
  border: solid 1px #d9d9d9;
  border-radius: 5px !important;
  background-color: #ffffff;
  text-align: initial;
  color: #000000;
  font-weight: 600;
  font-family: Source Sans Pro - Semibold;
}
.langDrop .langDropdownSize .langDropdownContent {
  width: 335px;
  border-radius: 5px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: #ffffff;
  color: #000000;
}
.langDrop .langDropdown .btn-primary:hover {
  background-color: white;
}
.langDropdown.dropdown-toggle::after {
  margin-left: 12.25rem !important;
  content: none;
}
.dropdownWrapper .form-group {
  overflow-y: scroll;
  height: 266px;
}
.langDropdownContent a:hover {
  background-color: black;
}

.cancelStyle {
  width: 150px !important;
  height: 50px !important;
  border-radius: 5px;
  background-color: #f2f2f2;
}

.saveStyle {
  width: 150px;
  height: 50px;
  border-radius: 5px;
  background-color: #e30613;
  margin-left: 33px;
}

.drop1Style {
  width: 320px !important;
  height: 50px !important;
  border-radius: 5px;
  background-color: #ffffff;
}

.drop2Style {
  width: 320px;
  height: 50px;
  border-radius: 5px;
  background-color: #ffffff;
  margin-bottom: 30px;
}

.textStyle {
  width: 46px;
  height: 20px;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #626468;
}

.saveTextStyle {
  width: 33px;
  height: 20px;
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.backButton {
  display: none;
}
.optionBold{
  font-weight: 600 !important;
}
 /* .optionStyle:focus{
  border: 2px solid black !important;
} */
.confirmStyle {
  display: none;
}
.optionStyle {
  margin: 8px;
  cursor: pointer;
}
/* .optionStyle:hover{
  font-weight: 600 !important;
} */
.optionStyle :hover {
  background-color: #f2f2f2;
  width: auto;
  height: auto;
}
.footerButtons {
  margin-top: 30px;
}
.horz {
  display: none;
}
.MobileSet {
  display: none;
}

@media screen and (max-width: 460px) {
  .desktopSet {
    display: none;
  }
  .MobileSet {
    display: inline-block;
  }
  /* .padLeft {
 
  } */
  .modalStyle .modal-content {
    width: 100% !important;
    height: 112vh;
    border-radius: 40px;
    box-shadow: none !important;
    
  }
  /* .modalStyle .modal-body {
    margin: auto;
  } */

  .defaultPage {
    width: 100%;
    /* padding-bottom: 30px;
    padding-top: 20px; */
    /* padding: 0%; */
  }
  .defaultscreen{
    width: 100%;
  }
  .langDrop .langDropdownSize .langDropdown {
    /* width: 100% !important; */
    /* margin-top: 27px; */
  }
  .langDrop .langDropdownSize .langDropdownContent {
    /* width: 100%; */
    
  }
  .modalStyle .modal-dialog {
    margin: 0px;
   padding: 0px !important;
  }
  .backButton {
    display: inline-block;
    padding-right: 55px;
  }
  .titleStyle {
    width: 100%;
    height: 20px;
    font-family: Source Sans Pro;
    font-size: 16px !important;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal !important;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
  }
  .option:hover {
    background-color: red;
  }
  /* .horz
{
  display: block !important;
 width: 350px !important;
  border-left: 2px solid black;
  padding-left: 0px !important;
  
} */
.regionDrop .regionDropdownContent .dropdownWrapper {
  /* width: 100%; */
  
}
.regionDrop .regionDropdownSize .regionDropdown {
  /* width: 100% !important; */
 
}
.regionDrop .regionDropdownSize .regionDropdownContent {
  /* width: 100%; */
  
}
  .confirmStyle {
    display: inline-block !important;
    width: 353px !important;
    height: 44px !important;
    border-radius: 5px;
    background-color: #e30613;
    /* width: 342px !important; */
    margin-top: 359px;
    margin-bottom: 21px;
  }
  .confirmText {
    width: 56px;
    height: 20px;
    font-family: Source Sans Pro;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  .footerButtons {
    display: none;
  }
}
