
.Spinner{
	height: 55vh;
	width: 100%;
    display: flex;
    opacity: 0;
    justify-content: center;
    animation-name: fadeIn;
    animation-duration: 1200ms;
    animation-fill-mode: forwards;
    align-items: center;
}

.Spinner.SpinnerDots {
	
	.spinner-dot{
		animation-name: dance;
		animation-duration: 1000ms;
		animation-iteration-count: infinite;
		animation-timing-function: ease-in-out;
		height:12px;
		width:12px;
		background-color: #242429;
		margin:3px;
		border-radius:12px;
	}
	
	.spinner-dot:nth-child(1){
		animation-delay: 0;
	}
	
	.spinner-dot:nth-child(2){
		animation-delay: 333ms;
	}
	
	.spinner-dot:nth-child(3){
		animation-delay: 666ms;
	}
	}




@keyframes fadeIn{
	0%{
		opacity:0;
	}
	
	100%{
		opacity:1;
	}
}


@keyframes scaleDance{
	0%{
		transform: scale(1);
			opacity: 0.6;
	}
	
	50%{
		transform: scale(1.7434);
		opacity:0;
	}
	
	100%{
		opacity:0;
	}
	
}


@keyframes explode{
	0%{
		transform: scale(1);
			opacity: 0.4;
	}
	
	50%{
		transform: scale(1.25);
		opacity:1;
	
	}
	
	100%{
		transform: scale(1);
			opacity:0.4;
	}
}

@keyframes dance {
	
	0% {
		transform: translateY(0);
	}
	
	50%{
		transform: translateY(12px);
	}
	
  100% {
		transform: translateY(0);
	}
  
}